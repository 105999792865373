import React, { useReducer, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { FormGroup } from '@material-ui/core';
import { Checkbox, ExpansionPanel } from 'shared';

import {
    GET_EXPERTISES,
    GET_VACANCY_TYPES,
    GET_WORKING_HOURS,
    GET_PROVINCES,
    GET_DURATIONS,
    GET_PUB_DATES,
    GET_REGIONS
} from '../../queries';
import { useTranslation } from 'react-i18next';

const Filtercontent = (props) => {
    const { loading = false, onChange = false, filters = {} } = props;
    const {
        expertiseList: expertiseListFilter = [],
        type: typeFilter = [],
        provinces: provincesFilter = [],
        durations: durationsFilter = [],
        workingHours: workingHoursFilter = [],
        publicationDates: publicationDatesFilter = [],
        regions: regionsFilter = [],
        visibleInApi = false
    } = filters;

    const expertiseList = Array.isArray(expertiseListFilter) ? expertiseListFilter : [expertiseListFilter];
    const type = Array.isArray(typeFilter) ? typeFilter : [typeFilter];
    const provinces = Array.isArray(provincesFilter) ? provincesFilter : [provincesFilter];
    const durations = Array.isArray(durationsFilter) ? durationsFilter : [durationsFilter];
    const workingHours = Array.isArray(workingHoursFilter) ? workingHoursFilter : [workingHoursFilter];
    const publicationDates = Array.isArray(publicationDatesFilter) ? publicationDatesFilter : [publicationDatesFilter];
    const regions = Array.isArray(regionsFilter) ? regionsFilter : [regionsFilter];

    const [expanded, setExpanded] = useReducer((state, action) => {
        return { ...state, ...action };
    }, {});

    const { t } = useTranslation();
    const { loading: loadingExpertises, data: expertisesData } = useQuery(GET_EXPERTISES);
    const { loading: loadingTypes, data: typesData } = useQuery(GET_VACANCY_TYPES);
    const { loading: loadingHours, data: hoursData } = useQuery(GET_WORKING_HOURS);
    const { loading: loadingProvinces, data: provincesData } = useQuery(GET_PROVINCES);
    const { loading: loadingDurations, data: durationsData = {} } = useQuery(GET_DURATIONS);
    const { loading: loadingPubdates, data: pubDatesData = {} } = useQuery(GET_PUB_DATES);
    const { loading: loadingRegions, data: regionsData = {} } = useQuery(GET_REGIONS);

    useEffect(() => {
        if (!loading) {
            const obj = {};

            Object.keys(filters).forEach((key) => {
                if (filters[key] && filters[key].length) obj[key] = true;
            });

            setExpanded({ ...obj });
        }
    }, [loading, filters]);

    return (
        <React.Fragment>
            <ExpansionPanel title="Vakgebied" expanded={expanded.expertiseList || false}>
                <FormGroup>
                    {!loadingExpertises &&
                        expertisesData &&
                        expertisesData.expertises.expertises.map((expertise) => (
                            <Checkbox
                                key={expertise.id}
                                label={expertise.description}
                                onChange={onChange}
                                name={expertise.id}
                                arrayName="expertiseList"
                                loading={loading || loadingExpertises}
                                checked={expertiseList.some((i) => i.toString() === expertise.id.toString())}
                            />
                        ))}
                </FormGroup>
            </ExpansionPanel>
            <ExpansionPanel title="Provincie" expanded={expanded.provinces || false}>
                <FormGroup>
                    {!loadingProvinces &&
                        provincesData &&
                        provincesData.provinces.map((city) => (
                            <Checkbox
                                key={city}
                                label={city}
                                onChange={onChange}
                                name={city}
                                arrayName="provinces"
                                loading={loading}
                                checked={provinces.some((i) => i.toString() === city.toString())}
                            />
                        ))}
                </FormGroup>
            </ExpansionPanel>
            <ExpansionPanel title="Regio" expanded={expanded.regions || false}>
                <FormGroup>
                    {!loadingRegions &&
                        regionsData.regions &&
                        regionsData.regions.regions.map((region) => (
                            <Checkbox
                                key={region.id}
                                label={region.description}
                                onChange={onChange}
                                name={region.id}
                                arrayName="regions"
                                loading={loading}
                                checked={regions.some((i) => i.toString() === region.id.toString())}
                            />
                        ))}
                </FormGroup>
            </ExpansionPanel>
            <ExpansionPanel title="Soort Kans" expanded={expanded.type || false}>
                <FormGroup>
                    {!loadingTypes &&
                        typesData &&
                        typesData.paginatedVacancyTypes &&
                        typesData.paginatedVacancyTypes.types &&
                        typesData.paginatedVacancyTypes.types.map((t) => (
                            <Checkbox
                                key={t.id}
                                label={t.description}
                                onChange={onChange}
                                name={t.id}
                                tooltip={t.explanation}
                                arrayName="type"
                                loading={loading}
                                checked={type.some((i) => i.toString() === t.id.toString())}
                            />
                        ))}
                </FormGroup>
            </ExpansionPanel>
            <ExpansionPanel title="Duur" expanded={expanded.durations || false}>
                <FormGroup>
                    {!loadingDurations &&
                        durationsData.durations &&
                        durationsData.durations.durations.map((t) => (
                            <Checkbox
                                key={t.id}
                                label={t.description}
                                onChange={onChange}
                                name={t.id}
                                arrayName="durations"
                                loading={loading}
                                checked={durations.some((i) => i.toString() === t.id.toString())}
                            />
                        ))}
                </FormGroup>
            </ExpansionPanel>
            <ExpansionPanel title="Uren per week" expanded={expanded.workingHours || false}>
                <FormGroup>
                    {!loadingHours &&
                        hoursData &&
                        hoursData.workingHoursList.workingHoursList.map((hour) => (
                            <Checkbox
                                key={hour.id}
                                label={`${hour.value} uur`}
                                onChange={onChange}
                                name={hour.id}
                                arrayName="workingHours"
                                loading={loading}
                                checked={workingHours.some((i) => i.toString() === hour.id.toString())}
                            />
                        ))}
                </FormGroup>
            </ExpansionPanel>
            <ExpansionPanel title="Plaatsingsdatum" expanded={expanded.publicationDates || false}>
                <FormGroup>
                    {!loadingPubdates &&
                        pubDatesData.publicationDates &&
                        pubDatesData.publicationDates.publicationDates.map((date) => (
                            <Checkbox
                                key={date.id}
                                label={`${date.description}`}
                                onChange={onChange}
                                name={date.id}
                                arrayName="publicationDates"
                                loading={loading}
                                checked={publicationDates.some((i) => i.toString() === date.id.toString())}
                            />
                        ))}
                </FormGroup>
            </ExpansionPanel>
            <ExpansionPanel title={t('filter.other')} expanded={expanded.extra || false}>
                <FormGroup>
                    <Checkbox
                        label={t('filter.visibleInApi')}
                        onChange={onChange}
                        name={'visibleInApi'}
                        loading={loading}
                        checked={visibleInApi}
                    />
                </FormGroup>
            </ExpansionPanel>
        </React.Fragment>
    );
};

export default Filtercontent;
