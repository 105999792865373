import { useReducer } from 'react';
import { createErrorMessage } from './createErrorMessage';
import { useTranslation } from 'react-i18next';
import { validateEntity, Failure } from 'validation';

let timer;

const useForm = (initialValue = {}) => {
    const { t } = useTranslation();

    const reducer = (state, data) => {
        if (data.type && data.type === 'remove' && data.data) {
            const obj = {};
            Object.keys(state).forEach((key) => {
                if (key !== data.data) {
                    obj[key] = state[key];
                }
            });
            return { ...obj };
        }
        return {
            ...state,
            ...data
        };
    };

    const [fields, setFields] = useReducer(
        reducer,
        Object.keys(initialValue).reduce((fields, key) => {
            fields[key] = null;
            return fields;
        }, {})
    );

    const [errors, setErrors] = useReducer(
        reducer,
        Object.keys(initialValue).reduce((errors, key) => {
            errors[key] = null;
            return errors;
        }, {})
    );

    const formFunctions = {
        onFieldChange: (data) => {
            clearTimeout(timer);
            if (data.smooth) {
                timer = setTimeout(() => {
                    setFields({ [data.key]: data.value });
                    setErrors({ ...errors, [data.key]: [] });
                }, 200);
            } else {
                setFields({ [data.key]: data.value });
                setErrors({ ...errors, [data.key]: [] });
            }
        },
        onRemove: (data) => {
            setFields({ type: 'remove', data: data });
        },
        fields,
        errors
    };

    const submitFunction = () => {
        return new Promise((resolve, reject) => {
            let _errors = {};
            const fieldsToValidate = Object.keys(fields);

            const validationResults = validateEntity(
                Object.keys(initialValue).reduce((s, key) => {
                    if (fieldsToValidate.includes(key)) {
                        s[key] = initialValue[key];
                    }
                    return s;
                }, {}),
                fields
            );

            if (Failure.hasInstance(validationResults)) {
                _errors = validationResults.value.reduce((result, { propertyName, errors }) => {
                    const currentResult = result[propertyName] || [];
                    result[propertyName] = currentResult.concat(
                        errors.map((e) => createErrorMessage(initialValue[propertyName], e, t))
                    );

                    return result;
                }, {});
            }

            if (Object.keys(_errors).reduce((hasErrors, key) => hasErrors || _errors[key].length > 0, false)) {
                setErrors(_errors);
                reject(_errors);
            } else {
                resolve(fields);
            }
        });
    };

    return [formFunctions, submitFunction];
};

export default useForm;
