import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import merge from 'deepmerge';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Paper, Box } from '@material-ui/core';

import { LoaderModal } from 'shared';
import { Button } from 'shared';
import { GET_CURRENT_USER } from './queries';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.secondary,
        padding: theme.spacing(0, 3, 0),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(7.5, 0, 0)
        }
    },
    grid: {
        maxWidth: '1200px',
        margin: '0 auto'
    },
    gridItem: {
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1)
        }
    },
    paper: {
        width: '100%',
        padding: `${theme.spacing(6)}px`,
        borderRadius: '25px',
        marginBottom: `${theme.spacing(2.5)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3.5)
        }
    },
    heading: {
        ...theme.typography.h6,
        fontWeight: 600,
        paddingBottom: `${theme.spacing(2.5)}px`,
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: `${theme.spacing(2.5)}px`
    },
    list: {},
    item: {
        border: `1px solid ${theme.palette.text.secondary}`,
        borderRadius: '35px',
        color: theme.palette.text.secondary,
        fontSize: '1em',
        margin: theme.spacing(0, 1.3, 1.3, 0),
        padding: `${theme.spacing(0.4)}px ${theme.spacing(1.8)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 2),
            lineHeight: 1.25,
            fontSize: '0.9em'
        }
    },
    recent: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap'
    },
    bigAvatar: {
        width: '185px',
        height: '185px',
        border: '5px solid ' + theme.palette.common.white,
        boxShadow: theme.shadows[2],
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            top: '-120px'
        }
    },
    avatar: {
        position: 'relative',
        width: '185px',
        height: '72px',
        marginRight: `${theme.spacing(3.125)}px`,
        [theme.breakpoints.down('sm')]: {
            margin: `-${theme.spacing(8)}px auto ${theme.spacing(2)}px`,
            order: '-1',
            height: 'auto'
        }
    },
    flex: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '73px',
        flexFlow: 'column nowrap',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            flexFlow: 'row wrap',
            textAlign: 'left'
        }
    },
    button: {
        marginBottom: `${theme.spacing(2.5)}px`,
        fontSize: '1em',
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0, 0)
        }
    },
    buttonWrap: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    itemRow: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        paddingBottom: `${theme.spacing(2.5)}px`,
        marginBottom: `${theme.spacing(2.5)}px`,
        '&:last-child': {
            borderBottom: 0,
            marginBottom: 0,
            paddingBottom: 0
        }
    }
}));

const UserProfileView = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [draft, setDraft] = useState({
        corporation: {
            name: ''
        },
        profile: {
            firstName: '',
            lastName: '',
            middleName: '',
            fullName: '',
            dateOfBirth: '',
            city: {
                name: ''
            },
            educationGrade: '',
            position: '',
            salaryScale: '',
            workingHoursPerWeek: 'Onbekend',
            region: {
                name: ''
            },
            profilePicture: {
                name: '',
                url: ''
            },
            skillList: [],
            expertiseList: [],
            workExperience: [],
            educations: [],
            motivation: '',
            biography: '',
            preferredPosition: '',
            preferredExpertiseList: [],
            specialties: []
        }
    });
    const [width, setWidth] = React.useState(window.innerWidth);

    const { loading, data = {} } = useQuery(GET_CURRENT_USER, {
        fetchPolicy: 'no-cache'
    });

    //functions
    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
    };

    const removeEmpty = useCallback((obj) => {
        Object.keys(obj).forEach(function (key) {
            if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
            else if (obj[key] == null) delete obj[key];
        });
    }, []);

    //effect hooks
    useEffect(() => {
        window.addEventListener('resize', updateWidthAndHeight);
        return () => window.removeEventListener('resize', updateWidthAndHeight);
    });

    useEffect(() => {
        if (width) {
            setWidth(width);
        }
    }, [width]);

    useEffect(() => {
        if (!data) return;
        if (data.currentUser) {
            const dbData = data.currentUser;
            removeEmpty(dbData);
            setDraft((draft) => merge.all([draft, dbData]));
        }
    }, [data, removeEmpty]);

    return (
        <div className={classes.root}>
            <Grid className={classes.grid} container justify="center" alignItems="flex-start">
                <Grid item xs={12} md={9} className={classes.gridItem}>
                    <Paper elevation={0} className={classNames(classes.paper, classes.flex)}>
                        <div>
                            <Typography className={classes.title} variant="h3">
                                {`${draft.profile.fullName}`}
                            </Typography>
                            <Typography className={classes.title} variant="h6">
                                {draft.profile.position}
                            </Typography>
                        </div>

                        {width < 960 && (
                            <Button
                                className={classes.button}
                                label="Profiel bewerken"
                                variant="outlined"
                                component={Link}
                                to="/mijn-gegevens/bewerken"
                                iconLeft={() => <Edit />}
                            />
                        )}
                    </Paper>
                </Grid>

                <Grid item xs={12} md={3} className={classes.gridItem}>
                    {width >= 960 && (
                        <Grid item xs={12} className={classes.buttonWrap}>
                            <Button
                                className={classes.button}
                                label="Profiel bewerken"
                                variant="outlined"
                                component={Link}
                                to="/mijn-gegevens/bewerken"
                                iconLeft={() => <Edit />}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Paper elevation={0} className={classes.paper}>
                            <Typography className={classes.heading}>{t('userProfile.titles.situation')}</Typography>
                            <Typography variant="subtitle1">{t('userProfile.titles.city')}</Typography>
                            <Typography variant="body1">{draft.profile.city.name}</Typography>
                            <Box mb={1} />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <LoaderModal loading={loading} />
        </div>
    );
};

export default UserProfileView;
