import { ApolloProvider } from '@apollo/client';
import { client } from 'api';
import { Provider } from 'react-redux';
import { Snackbar } from 'shared';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import Bootstrap from 'components/Bootstrap';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import store from 'store';
import Theme from 'styling';

const useStyles = makeStyles(() => ({
    root: {
        overflowX: 'hidden',
        position: 'relative'
    }
}));

const Root = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Provider store={store}>
                <ApolloProvider client={client}>
                    <ThemeProvider theme={Theme}>
                        <CssBaseline />
                        <SnackbarProvider
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            content={(key, object) => (
                                <Snackbar id={key} message={object.message} variant={object.variant} />
                            )}
                        >
                            <Bootstrap />
                        </SnackbarProvider>
                    </ThemeProvider>
                </ApolloProvider>
            </Provider>
        </div>
    );
};

export default Root;
