import React, { useEffect } from 'react';
import { InputField } from 'shared';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& $inputComponent': {
            border: `1px solid ${theme.palette.primary.light}`,
            borderRadius: '3px',
            padding: theme.spacing(1.5, 8, 1.5, 1.875),
            height: '100%',
            '&:before, &:after': {
                display: 'none'
            },
            '&:focus': {
                border: `1px solid ${theme.palette.primary.main}`,
                boxShadow: '0 0 0 4px #E7DCEC'
            }
        },
        '& $nativeInput': {
            padding: 0,
            color: 'currentColor'
        },
        '& $inputLabel': {
            position: 'relative',
            fontSize: '1.125rem',
            fontWeight: 500,
            transform: 'none',
            userSelect: 'none',
            paddingBottom: `${theme.spacing(0.5)}px`,
            '& + $inputElement': {
                marginTop: '8px'
            }
        }
    },
    circularProgress: {
        display: 'flex',
        color: theme.palette.primary.main
    },
    circularProgressSvg: {
        transformOrigin: 'center',
        width: '100%',
        height: '100%'
    },
    inputLabel: {},
    inputElement: {},
    inputComponent: {},
    nativeInput: {},
    textField: {},
    endAdornment: {}
}));

const AutoCompleteInput = (props) => {
    const {
        data,
        noOptionsText,
        loadingText,
        form,
        label,
        placeholder,
        name,
        onChange,
        initialValue,
        errorMessage: errorMessageFromProps,
        error: errorFromProps,
        onKeyPress,
        hint
    } = props;
    const classes = useStyles(props);

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [responseText, setResponseText] = React.useState();
    const [error, setError] = React.useState(errorFromProps);
    const [errorMessage, setErrorMessage] = React.useState(errorMessageFromProps);
    const [value, setValue] = React.useState();

    const [getLazyQuery, { loading, data: lazyQueryData }] = useLazyQuery(data.query);

    useEffect(() => {
        if (!loading) {
            if (
                lazyQueryData &&
                lazyQueryData[data.response][data.response] &&
                lazyQueryData[data.response][data.response].length
            ) {
                setOptions(lazyQueryData[data.response][data.response]);
            } else {
                setResponseText('Geen resultaten');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyQueryData, loading]);

    useEffect(() => {
        if (initialValue && initialValue.name) {
            getLazyQuery({
                variables: {
                    filter: {
                        search: initialValue.name
                    }
                }
            });
            setValue(initialValue);
            form &&
                form.onFieldChange({
                    key: name,
                    value: initialValue || ''
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    useEffect(() => {
        if (searchQuery && searchQuery.length > 1) {
            getLazyQuery({
                variables: {
                    filter: {
                        search: searchQuery
                    }
                }
            });
        } else {
            setOptions([]);
            setResponseText();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    useEffect(() => {
        setError(errorFromProps);
        setErrorMessage(errorMessageFromProps);
    }, [errorFromProps, errorMessageFromProps]);

    useEffect(() => {
        if (form) {
            if (form.errors && form.errors[name] && form.errors[name].length) {
                setError(true);
                setErrorMessage(form.errors[name].join(','));
            } else {
                setError(false);
                setErrorMessage('');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const handleAutocompleteChange = (e, value) => {
        form &&
            form.onFieldChange({
                key: name,
                value: value || ''
            });
        if (!value) {
            setValue();
            setOptions([]);
            setResponseText();
        } else {
            setValue(value);
        }
    };

    const handleInputChange = (input) => {
        if (input) {
            setSearchQuery(input.target.value);
        }
    };

    return (
        <Autocomplete
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            classes={{
                root: classes.root,
                input: classes.nativeInput,
                endAdornment: classes.endAdornment
            }}
            onChange={onChange || handleAutocompleteChange}
            onInputChange={handleInputChange}
            getOptionSelected={(option, value) => option.name === value.name}
            value={value || null}
            getOptionLabel={initialValue ? (initialValue) => initialValue.name : (option) => option.name}
            noOptionsText={responseText || noOptionsText || 'Geen opties'}
            options={options.map((option) => option)}
            loading={loading}
            loadingText={loadingText || 'Laden...'}
            renderInput={(params) => (
                <InputField
                    {...params}
                    label={label || false}
                    fullWidth
                    form={form}
                    onKeyPress={onKeyPress}
                    error={error}
                    placeholder={placeholder}
                    name={name}
                    helperText={error ? errorMessage : '' || hint}
                    className={classes.textField}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: name || 'off'
                    }}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        className: classes.inputComponent,
                        endAdornment: (
                            <React.Fragment>
                                {loading && (
                                    <CircularProgress
                                        classes={{
                                            root: classes.circularProgress,
                                            svg: classes.circularProgressSvg
                                        }}
                                        color="inherit"
                                        size={16}
                                    />
                                )}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                    InputLabelProps={{
                        shrink: false,
                        className: classes.inputLabel
                    }}
                />
            )}
        />
    );
};

export default AutoCompleteInput;
