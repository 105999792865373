import React from 'react';
import { useTranslation } from 'react-i18next';

import { Room, AccessTime, Check, Person as PersonIcon } from '@material-ui/icons';
import { Typography, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

import { Button, RichTextContainer, VideoPlayer, Link } from 'shared';
import { FavoriteButton } from 'shared';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1400px',
        width: '100%',
        margin: '0 auto',
        padding: `${theme.spacing(4)}px ${theme.spacing(0)}px`,
        borderBottom: `1px solid ${theme.palette.borderColor.main}`,
        textDecoration: 'none',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'stretch'
    },
    topBar: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: `${theme.spacing(2)}px`,
        flex: '0 1 550px'
    },
    content: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down(768)]: {
            flexFlow: 'column nowrap'
        }
    },
    left: {
        width: '100%',
        paddingRight: '20px',
        [theme.breakpoints.down(768)]: {
            paddingRight: 0
        }
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.secondary.main
        }
    },
    video: {
        width: '100%',
        maxWidth: '300px'
    },
    subTitle: {
        lineHeight: '20px'
    },
    title: {
        ...theme.typography.h6,
        textDecoration: 'none',
        fontSize: '1.4em',
        width: '100%',
        paddingBottom: `${theme.spacing(1)}px`,
        flex: '0 0 100%'
    },
    infoBar: {
        display: 'flex',
        flex: '0 0 calc(100% - 100px)',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    infoItem: {
        marginRight: `${theme.spacing(4)}px`,
        display: 'flex',
        alignItems: 'center',
        opacity: 0.6,
        color: theme.palette.text.secondary,
        '& .MuiSvgIcon-root': {
            marginRight: `${theme.spacing(1)}px`
        }
    },
    infoItemPlace: {
        lineHeight: '1.2',
        marginRight: 0
    },
    introduction: {
        color: theme.palette.text.normal,
        flex: '1 1 auto',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        paddingRight: `${theme.spacing(3.125)}px`,
        [theme.breakpoints.up(768)]: {
            flexBasis: '550px'
        },
        [theme.breakpoints.down(768)]: {
            paddingRight: 0
        }
    },
    links: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '230px',
        marginTop: theme.spacing(2),
        flex: '0 0 auto',
        justifyContent: 'space-between',
        position: 'relative',
        [theme.breakpoints.up(768)]: {
            flexBasis: '230px'
        }
    },
    approached: {
        position: 'absolute',
        top: '-52px',
        left: '0',
        fontWeight: 500,
        color: theme.palette.text.secondary
    },
    check: {
        color: theme.palette.secondary.light,
        transform: 'translateY(4px)'
    },
    button: {
        height: '60px',
        alignSelf: 'flex-end',
        background: theme.palette.secondary.light,
        '& span': {
            margin: '0'
        }
    },
    favorite: {
        height: '60px',
        alignSelf: 'flex-end'
    },
    image: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80px',
        height: '80px',
        flex: '0 0 80px',
        borderRadius: '50%',
        marginRight: `${theme.spacing(3.125)}px`,
        backgroundColor: theme.palette.divider
    },
    imagePlaceholder: {
        width: '1.5em',
        height: '1.5em'
    }
}));

const CandidateItem = (props) => {
    const {
        title,
        link,
        place,
        format = true,
        hours,
        introduction,
        profilePicture,
        approached = false,
        liked = false,
        id,
        data = {}
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <div className={classes.topBar}>
                {profilePicture ? (
                    <CardMedia className={classes.image} image={profilePicture} />
                ) : (
                    <CardMedia className={classes.image}>
                        <PersonIcon className={classes.imagePlaceholder} />
                    </CardMedia>
                )}
                <span className={classes.infoBar}>
                    {title && (
                        <Link to={link} className={classes.title}>
                            {title}
                        </Link>
                    )}

                    <Typography className={classes.infoItem} variant="body1">
                        <AccessTime /> {hours ? hours : 'Onbekend'}
                    </Typography>

                    <Typography className={classNames(classes.infoItem, classes.infoItemPlace)} variant="body1">
                        <Room /> {place ? place : 'Onbekend'}
                    </Typography>
                </span>
            </div>
            <div className={classes.content}>
                <div className={classes.left}>
                    {introduction && introduction.length && (
                        <RichTextContainer className={classes.introduction} format={format} content={introduction} />
                    )}
                    {data.videoURL && (
                        <div className={classes.video}>
                            <VideoPlayer url={data.videoURL} />
                        </div>
                    )}
                </div>
                <div className={classes.links}>
                    {approached && (
                        <Typography variant="body2" className={classes.approached}>
                            <Check className={classes.check} /> Kandidaat benaderd
                        </Typography>
                    )}
                    {data.cv ||
                        (data.attachtments && (
                            <div>
                                {data.cv && data.cv.url ? (
                                    <Link className={classes.link} target="_blank" to={data.cv.url}>
                                        CV downloaden
                                    </Link>
                                ) : (
                                    ''
                                )}
                                {data.attachments &&
                                    data.attachments.map((attachment, attachmentIndex) => (
                                        <Link
                                            key={attachmentIndex}
                                            idx={attachment.id}
                                            className={classes.link}
                                            target="_blank"
                                            to={attachment.url}
                                        ></Link>
                                    ))}
                            </div>
                        ))}
                    {link && (
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="secondary"
                            label={t('candidateItem.button')}
                            component={Link}
                            to={link}
                        />
                    )}
                    <FavoriteButton className={classes.favorite} type="user" id={id} liked={liked} />
                </div>
            </div>
        </div>
    );
};

export default CandidateItem;
