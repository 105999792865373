import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { setUserData } from 'store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { FavoriteBorder } from '@material-ui/icons';

import { Table, Search, SwipeWrapper } from 'shared';
import { LoaderModal } from 'shared';

import VacancyRow from './VacancyRow';
import AlertRow from './AlertRow';

import { convertToSlug } from 'helpers';

import { GET_LIKED, SET_LIKE_VACANCY, SEARCH_TRIGGERS, DELETE_ALERT, UPDATE_ALERT } from '../queries';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '988px',
        padding: theme.spacing(0, 3),
        margin: `${theme.spacing(10)}px auto`
    },
    number: {
        fontWeight: 300,
        color: theme.palette.text.secondaryLight,
        fontSize: '24px',
        marginLeft: `${theme.spacing(1)}px`
    },
    item: {
        marginTop: `${theme.spacing(8)}px`
    },
    table: {
        [theme.breakpoints.down(1025)]: {
            minWidth: '1400px'
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '900px'
        }
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px'
        }
    },
    icon: {
        marginRight: `${theme.spacing(2)}px`,
        color: theme.palette.primary.main,
        padding: '2px'
    }
}));

const MyVacancies = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const userId = useSelector((state) => state.auth.userId);
    const { enqueueSnackbar } = useSnackbar();

    const { data: alertData = {}, loading: loadingAlert, refetch: refetchAlerts } = useQuery(SEARCH_TRIGGERS);
    const { searchTriggers = [] } = alertData;

    const {
        data: likedData = {},
        loading: loadingLiked,
        refetch
    } = useQuery(GET_LIKED, {
        variables: {
            id: userId
        }
    });

    const [likeVacancy, { loading: loadingMutation }] = useMutation(SET_LIKE_VACANCY);
    const [deleteAlert, { loading: loadingDelete }] = useMutation(DELETE_ALERT);
    const [updateAlert, { loading: loadingUpdate }] = useMutation(UPDATE_ALERT);

    const likedVacancies = likedData?.user?.likedVacancies || [];

    const handleVacancyClick = (id, title) => {
        history.push(`/kansen/${id}/${convertToSlug(title)}`);
    };

    const handleDeleteVacancy = (id) => {
        likeVacancy({
            variables: {
                input: {
                    like: false,
                    vacancyId: id
                }
            }
        });

        dispatch(setUserData(userId)); // Re-fetch user to state to update the list of liked vacancies and such, which may've been altered by deletion
        refetch();
    };

    const handleAlertClick = (id, active) => {
        if (!active) {
            enqueueSnackbar({
                variant: 'success',
                message: 'Vanaf nu krijg je een alert van deze zoekopdracht'
            });
        } else {
            enqueueSnackbar({
                variant: 'warning',
                message: 'Vanaf nu krijg je geen alert meer van deze zoekopdracht'
            });
        }

        updateAlert({
            variables: {
                input: {
                    id: id,
                    active: !active
                }
            }
        });
        refetchAlerts();
    };

    const handleDeleteAlert = (id) => {
        deleteAlert({
            variables: {
                id: id
            }
        });
        refetchAlerts();
    };

    const setSearch = (alert) => {
        let searchString = `page=0&pageSize=10`;
        if (alert.distance) {
            searchString += `&distance=${alert.distance}`;
        }
        if (alert.city && alert.city.id) {
            searchString += `&city=${alert.city.id}`;
        }
        if (alert.search) {
            searchString += `&search=${alert.search}`;
        }
        if (alert.regions) {
            alert.regions.forEach((region) => {
                searchString += `&regions=${region}`;
            });
        }
        if (alert.expertiseList) {
            alert.expertiseList.forEach((expertise) => {
                searchString += `&expertiseList=${expertise}`;
            });
        }
        if (alert.provinces) {
            alert.provinces.forEach((province) => {
                searchString += `&provinces=${province}`;
            });
        }
        if (alert.durations) {
            alert.durations.forEach((duration) => {
                searchString += `&durations=${duration}`;
            });
        }
        if (alert.workingHours) {
            alert.durations.forEach((workingHour) => {
                searchString += `&workingHours=${workingHour}`;
            });
        }
        if (alert.type) {
            alert.type.forEach((singleType) => {
                searchString += `&type=${singleType}`;
            });
        }
        if (alert.publicationDates) {
            alert.publicationDates.forEach((publicationDate) => {
                searchString += `&publicationDates=${publicationDate}`;
            });
        }

        history.push(`/kansen?${searchString}`);
    };

    return (
        <Grid className={classes.root}>
            <Grid container justify="center">
                <Typography variant="h1">Mijn kansen</Typography>
            </Grid>
            <Grid className={classes.item}>
                <Typography className={classes.title} variant="h5">
                    <FavoriteBorder className={classes.icon} />
                    <span>Opgeslagen kansen</span>
                    <span className={classes.number}>({likedVacancies.length})</span>
                </Typography>
                <SwipeWrapper>
                    <Table
                        head={['Kans', 'Uren p/w', 'Plaats', '']}
                        className={classes.table}
                        renderRows={() =>
                            likedVacancies &&
                            likedVacancies.map((vacancy, idx) => (
                                <>
                                    {vacancy.visibility.id !== 5 && (
                                        <VacancyRow
                                            key={idx}
                                            onClick={handleVacancyClick}
                                            onDelete={handleDeleteVacancy}
                                            count={likedVacancies.length}
                                            vacancy={vacancy}
                                        />
                                    )}
                                </>
                            ))
                        }
                    />
                </SwipeWrapper>
            </Grid>
            <Grid className={classes.item}>
                <Typography className={classes.title} variant="h5">
                    <Search className={classes.icon} />
                    <span>Opgeslagen zoekopdrachten en kansenalerts</span>
                    <span className={classes.number}>({searchTriggers.length})</span>
                </Typography>
                <SwipeWrapper>
                    <Table
                        className={classes.table}
                        head={['Zoekterm', 'Plaats', 'Afstand', '']}
                        renderRows={() =>
                            searchTriggers &&
                            searchTriggers.map((alert) => {
                                return (
                                    <AlertRow
                                        key={alert.id}
                                        onClick={handleAlertClick}
                                        onNavigate={setSearch}
                                        count={likedVacancies.length}
                                        alert={alert}
                                        onDelete={handleDeleteAlert}
                                    />
                                );
                            })
                        }
                    />
                </SwipeWrapper>
            </Grid>
            <LoaderModal loading={loadingDelete || loadingMutation || loadingAlert || loadingLiked || loadingUpdate} />
        </Grid>
    );
};

export default MyVacancies;
