import { gql } from 'graphql-tag';

export const LOGIN = gql`
    mutation login($email: String!, $password: String!) {
        login(input: { email: $email, password: $password }) {
            user {
                id
                email
            }
        }
    }
`;

export const LOGOUT = gql`
    mutation logout {
        logout
    }
`;

export const GET_USER = gql`
    query user($id: Long!) {
        user(id: $id) {
            id
            email
            roles {
                id
                name
                title
            }
            likes {
                id
            }
            likedVacancies {
                id
            }
            respondedVacancies {
                id {
                    userId
                    vacancyId
                }
            }
            corporation {
                domain
                id
                name
            }
            profile {
                firstName
                fullName
                lastName
                middleName
            }
        }
    }
`;

export const WORKING_HOURS_LIST = gql`
    query workingHoursList {
        workingHoursList {
            count
            workingHoursList {
                id
                lowerLimit
                upperLimit
                label: value
                value: id
            }
        }
    }
`;

export const CITIES = gql`
    query cities {
        cities {
            count
            cities {
                id
                title: name
                province
            }
        }
    }
`;

export const EXPERTISES = gql`
    query expertises {
        expertises(pagination: { page: 0, pageSize: 100 }) {
            count
            expertises {
                value: id
                label: description
            }
        }
    }
`;
