import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import AppFooter from 'components/AppFooter';
import AppHeader from 'components/AppHeader';
import Dashboard from 'components/Dashboard';
import HrDashboard from 'components/HrDashboard';
import Login from 'components/Login';
import { GTMLayer } from 'shared';
import { HrMyVacancies, Vacancies, VacancyDetail, MyVacancies, NewVacancy } from 'components/vacancy';
import { ChangeEmail, EmailSucces, EmailError, PasswordError, ValidateEmail, EmailValidationSucces } from 'shared';
import { RespondedCandidates, Candidates, CandidateDetail, MyCandidates } from 'components/Candidates';
import PrivateRoute from 'components/PrivateRoute';
import Register, { PasswordChange, ForgotPassword } from 'components/Register';
import Unauthorized from 'components/Unauthorized';
import { UserProfile, UserProfileView } from 'components/UserProfile';

const useStyles = makeStyles((theme) => ({
    appBarSpacer: {
        ...theme.mixins.toolbar,
        height: '80px'
    },
    page: {
        position: 'relative',
        left: '15px',
        right: '15px'
    },
    pageEnter: {
        opacity: 0,
        transform: 'translateX(5%)'
    },
    pageEnterActive: {
        opacity: 1,
        transform: 'translateX(0)',
        transition: 'opacity 300ms, transform 300ms'
    },
    pageExit: {
        opacity: 1,
        transform: 'translateX(0)'
    },
    pageExitActive: {
        opacity: 0,
        transform: 'translateX(-5%)',
        transition: 'opacity 300ms, transform 300ms'
    }
}));

const App = () => {
    const classes = useStyles();

    return (
        <Router>
            <GTMLayer>
                <AppHeader />
                <div className={classes.appBarSpacer} />
                <main>
                    <Switch>
                        {/* HR routes */}
                        <PrivateRoute path="/kandidaten" hrRoute exact component={Candidates} />
                        <PrivateRoute path="/mijn-kandidaten" hrRoute exact component={MyCandidates} />
                        <PrivateRoute path="/kandidaten/:id/:name" hrRoute component={CandidateDetail} />
                        <PrivateRoute path="/hr-kansen/nieuwe-kans" hrRoute component={NewVacancy} />
                        <PrivateRoute path="/hr-kansen/bewerken/:id/:name" hrRoute component={NewVacancy} />
                        <PrivateRoute path="/hr-kansen" exact hrRoute component={HrMyVacancies} />
                        <PrivateRoute path="/hr" exact hrRoute component={HrDashboard} />

                        {/* profile routes */}
                        <PrivateRoute path="/mijn-gegevens/bewerken/:external" component={UserProfile} />
                        <PrivateRoute path="/mijn-gegevens/bewerken" component={UserProfile} />
                        <PrivateRoute path="/mijn-gegevens" component={UserProfileView} />

                        {/* email wijzigen routes */}
                        <Route exact path="/email-wijzigen/succes" component={EmailSucces} />
                        <Route exact path="/email-wijzigen/er-ging-iets-mis" component={EmailError} />
                        <Route exact path="/email-wijzigen/wachtwoord-onjuist" component={PasswordError} />
                        <Route exact path="/email-wijzigen/validatie-succes" component={EmailValidationSucces} />
                        <Route exact path="/change/email/:token" component={ValidateEmail} />
                        <PrivateRoute exact path="/email-wijzigen" component={ChangeEmail} />

                        {/* vacancies route */}
                        <Route path="/kansen" exact component={Vacancies} />
                        <Route path="/kansen/:id/:name" exact component={VacancyDetail} />
                        <PrivateRoute path="/kansen/:id/:name/kandidaten" component={RespondedCandidates} />
                        <PrivateRoute path="/mijn-kansen" exact component={MyVacancies} />

                        {/* register routes */}
                        <Route path="/register/forgot-password" exact component={ForgotPassword} />
                        <Route path="/register/password" exact component={PasswordChange} />
                        <Route path="/register/password/:id" component={PasswordChange} />
                        <Route path="/register" exact component={() => <Register />} />

                        <Route path="/unauthorized" component={Unauthorized} />
                        <Route path="/login" component={() => <Login />} />
                        <Route path="/logout" component={() => <Login variant="logout" />} />
                        <Route path="/" exact component={Dashboard} />
                    </Switch>
                </main>
                {/* <MobileNotify /> */}
                <AppFooter />
            </GTMLayer>
        </Router>
    );
};

export default App;
