import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    loader: {
        position: 'absolute',
        zIndex: 1,
        margin: 'auto',
        alignSelf: 'center'
    },
    image: {
        width: '100%',
        height: 'auto',
        display: 'block',
        objectFit: 'cover'
    }
}));

const Image = (props) => {
    const { src, alt } = props;
    const classes = useStyles(props);

    //states
    const [loading, setLoading] = React.useState(true);

    const handleLoad = () => {
        setLoading(false);
    };

    return (
        <div className={classes.root}>
            {loading && <CircularProgress className={classes.loader} disableShrink size={32} />}
            <img src={src} className={classes.image} alt={alt} onLoad={handleLoad} hidden={loading} />
        </div>
    );
};

export default Image;
