import React from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { InputField, AutoCompleteInput, Link } from 'shared';
import { useStyles } from '../styles';

import { GET_CITIES } from '../queries';

const PersonalInfo = (props) => {
    const classes = useStyles();
    const { form, currentProfile = {}, currentUser = {}, id } = props;
    const { t } = useTranslation();

    return (
        <Grid item xs={12} id={id}>
            <Paper className={classes.paper} elevation={0}>
                <Typography className={classes.heading}>{t('userProfile.titles.personalInfo')}</Typography>
                <Grid container justify="space-between">
                    <Grid item xs={12} md={6}>
                        <InputField
                            className={classes.input}
                            initialValue={currentProfile.firstName || ''}
                            type="text"
                            label={t('profileForm.firstName')}
                            name="firstName"
                            form={form}
                        />
                        <InputField
                            className={classes.input}
                            initialValue={currentProfile.middleName || ''}
                            type="text"
                            label={t('profileForm.middleName')}
                            name="middleName"
                            form={form}
                        />
                        <InputField
                            className={classes.input}
                            initialValue={currentProfile.lastName || ''}
                            type="text"
                            label={t('profileForm.lastName')}
                            name="lastName"
                            form={form}
                        />
                        <AutoCompleteInput
                            label={t('profileForm.city')}
                            placeholder={t('profileForm.chooseCity')}
                            noOptionsText={t('profileForm.searchCity')}
                            className={classes.input}
                            initialValue={currentProfile.city || {}}
                            name="city"
                            form={form}
                            data={{
                                query: GET_CITIES,
                                response: 'cities'
                            }}
                        />
                        <div className={classes.emailContent}>
                            <Typography variant="h6" className={classes.subTitle}>
                                Email
                                {currentUser.emailIsCorporate && (
                                    <Link className={classes.changeEmail} to="/email-wijzigen">
                                        {' '}
                                        (wijzigen)
                                    </Link>
                                )}
                            </Typography>
                            <Typography variant="body1">{currentUser.email || ''}</Typography>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default PersonalInfo;
