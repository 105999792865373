import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { NestMenuItem } from 'shared';
import { MenuList, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    mainMenu: {
        padding: 0,
        outline: 'none',
        display: 'flex',
        position: 'fixed',
        top: '80px',
        left: 0,
        height: 'calc(100% - 80px)',
        width: '100%',
        background: theme.palette.background.primary,
        opacity: 0,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        visibility: 'hidden',
        transition: 'opacity 300ms, visibility 300ms',

        [theme.breakpoints.up('md')]: {
            top: 'inherit',
            position: 'relative',
            flexDirection: 'row',
            width: 'auto',
            opacity: 1,
            visibility: 'visible',
            height: 'auto',
            background: 'transparent'
        }
    },
    menuActive: {
        opacity: 1,
        visibility: 'visible'
    },
    mobile: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    link: {
        fontSize: '1.4rem',
        textDecoration: 'none',
        margin: theme.spacing(1.5, 0),
        padding: theme.spacing(1.5, 0),
        fontWeight: 500,
        lineHeight: '32px',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-2px',
            left: 0,
            height: '4px',
            width: '100%',
            backgroundColor: theme.palette.primary.main,
            opacity: 0,
            transition: 'opacity 300ms ease'
        },
        '&.Mui-selected': {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent'
            },
            '&::after': {
                opacity: 1
            }
        },
        '&:hover': {
            backgroundColor: 'transparent'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.1rem',
            padding: theme.spacing(3, 0),
            margin: 0,
            '&:hover': {
                '&::after': {
                    opacity: 1
                }
            }
        }
    }
}));

const NavBar = (props) => {
    const { links, open, onSucces, user, signOut } = props;
    const classes = useStyles();
    const location = useLocation();
    const [loggedIn, setLogged] = useState(false);

    useEffect(() => {
        if (user) {
            setLogged(true);
        }
    }, [user]);

    return (
        <MenuList component="nav" className={classNames(classes.mainMenu, { [classes.menuActive]: open })}>
            {links &&
                links.map((item, idx) =>
                    item.external ? (
                        <a
                            key={idx}
                            className={classes.link}
                            href={item.path}
                            target={item.target || '_blank'}
                            onClick={onSucces}
                        >
                            {item.linkText}
                        </a>
                    ) : item.mobile ? (
                        item.path.indexOf('login') > -1 ? (
                            loggedIn ? (
                                <MenuItem
                                    key={idx}
                                    variant="body1"
                                    component={Link}
                                    onClick={signOut}
                                    to={''}
                                    className={classNames(classes.link, classes.mobile)}
                                >
                                    Uitloggen
                                </MenuItem>
                            ) : (
                                <MenuItem
                                    key={idx}
                                    component={Link}
                                    className={classNames(classes.link, classes.mobile)}
                                    to={item.path}
                                    variant="body1"
                                    selected={location.pathname === item.path}
                                    onClick={onSucces}
                                >
                                    Inloggen
                                </MenuItem>
                            )
                        ) : (
                            <MenuItem
                                key={idx}
                                component={Link}
                                className={classNames(classes.link, classes.mobile)}
                                to={item.path}
                                variant="body1"
                                selected={location.pathname === item.path}
                                onClick={onSucces}
                            >
                                {item.linkText}
                            </MenuItem>
                        )
                    ) : item.children ? (
                        <NestMenuItem
                            key={idx}
                            idx={idx}
                            // eslint-disable-next-line react/no-children-prop
                            children={item.children}
                            item={item}
                            variant="body1"
                            selected={location.pathname === item.path}
                            onSucces={onSucces}
                        />
                    ) : (
                        <MenuItem
                            key={idx}
                            component={Link}
                            className={classes.link}
                            to={item.path}
                            variant="body1"
                            selected={location.pathname === item.path}
                            onClick={onSucces}
                        >
                            {item.linkText}
                        </MenuItem>
                    )
                )}
        </MenuList>
    );
};
export default NavBar;
