import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FilterOverview, SearchBar, VacancyItem } from 'shared';
import { useFilter } from 'hooks';
import { convertToSlug, isEmpty } from 'helpers';
import FilterContent from './FilterContent';
import { GET_VACANCIES } from '../queries';

const useStyles = makeStyles((theme) => ({
    searchBar: {
        backgroundColor: theme.palette.background.secondary,
        marginBottom: '40px'
    }
}));

const VacanciesPage = () => {
    const classes = useStyles();
    const query = useFilter({ filter: { draft: false } });
    const [currentSearchValue, setCurrentSearchValue] = useState({});
    const [getVacancies, { loading, data: vacancieData = {}, error }] = useLazyQuery(GET_VACANCIES);
    const { t } = useTranslation();
    const { vacancies = {} } = vacancieData;

    const user = useSelector((state) => state.auth.user);
    const loggedIn = !isEmpty(user);
    const likedVacancies = useSelector((state) => state.auth.user.likedVacancies);
    const [width, setWidth] = React.useState(window.innerWidth);
    const [showFilters, setShowFilters] = useState(true);

    useEffect(() => {
        width >= 960 ? setShowFilters(true) : setShowFilters(false);
    }, [width]);

    useEffect(() => {
        window.addEventListener('resize', updateWidthAndHeight);
        return () => window.removeEventListener('resize', updateWidthAndHeight);
    });

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        getVacancies({
            variables: { ...query.query }
        });
    }, [getVacancies, query.query]);

    useEffect(() => {
        if (error) {
            console.log('Error for snackbar', error);
        }
    }, [error]);

    const handleSearch = ({ search, city, distance }) => {
        query.setQuery({
            filter: {
                city: city && city.id ? city.id : '',
                distance: distance || '',
                search: search || ''
            }
        });
    };

    const setValue = (val) => {
        setCurrentSearchValue(val);
    };

    const handleFilterClick = () => {
        setShowFilters((prevState) => !prevState);
    };

    return (
        <React.Fragment>
            <Helmet>
                <link rel="canonical" href={window.location.origin + '/kansen'} />
            </Helmet>
            <div className={classes.searchBar}>
                <Container maxWidth="lg" className={classes.wrapper}>
                    <SearchBar
                        onChange={handleSearch}
                        variant="compact"
                        initialValue={query.filters || {}}
                        loading={loading}
                        currentValue={setValue}
                        filterClick={handleFilterClick}
                        hasFilters={width < 960}
                        accordion={width < 960}
                    />
                </Container>
            </div>
            <FilterOverview
                loading={loading}
                query={query}
                count={vacancies ? vacancies.count : 0}
                currentSearchValue={currentSearchValue}
                filterContent={FilterContent}
                alert={true}
                title={t('vacancies.filterSearchResults')}
                showFilters={showFilters}
            >
                <React.Fragment>
                    {Object.keys(vacancies).length > 0 && vacancies.vacancies.length > 0 ? (
                        vacancies.vacancies.map((vacancy) => (
                            <VacancyItem
                                key={vacancy.id}
                                title={vacancy.title}
                                id={vacancy.id}
                                contactPerson={vacancy.contactPerson}
                                path={`/kansen/${vacancy.id}/${convertToSlug(vacancy.title)}`}
                                type={vacancy.type.description}
                                place={vacancy.city ? vacancy.city.name : ''}
                                corporation={vacancy.corporation}
                                hours={
                                    vacancy.workingHoursAWeek
                                        ? `${vacancy.workingHoursAWeek.value} ${t('general.hour')}`
                                        : t('general.unknown')
                                }
                                introduction={vacancy.description}
                                showFavorite={loggedIn}
                                originURL={vacancy.originURL}
                                crawled={vacancy.crawled}
                                liked={likedVacancies ? likedVacancies.some((item) => item.id === vacancy.id) : false}
                            />
                        ))
                    ) : (
                        <span>{t('vacancies.notFound')}</span>
                    )}
                </React.Fragment>
            </FilterOverview>
        </React.Fragment>
    );
};

export default VacanciesPage;
