import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button } from 'shared';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1300px',
        minWidth: '968px',
        width: '100%',
        margin: '0 auto',
        paddingBottom: `${theme.spacing(12.5)}px`,
        textAlign: 'center'
    },
    title: {
        width: '100%',
        textAlign: 'center',
        marginTop: `${theme.spacing(8)}px`,
        marginBottom: '20px',
        position: 'relative'
    }
}));

const EmailError = () => {
    const classes = useStyles();
    const history = useHistory();

    const handleClick = () => {
        history.goBack();
    };

    return (
        <Grid container className={classes.root} spacing={8} justify="space-between">
            <Grid item xs={12}>
                <Typography className={classes.title} variant="h2">
                    Er ging iets mis
                </Typography>
                <Typography className={classes.title}>Je kunt alleen een corporatie-mailadres gebruiken.</Typography>
                <Button variant="contained" color="secondary" label="Nog een keer proberen" onClick={handleClick} />
            </Grid>
        </Grid>
    );
};

export default EmailError;
