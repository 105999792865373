import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useQuery, useMutation } from '@apollo/client';
import { Badge, Popover, Typography, IconButton, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

import { Button, ArrowRight, Notification } from 'shared';
import { convertToSlug } from 'helpers';
import { TRIGGER_RESULTS, UPDATE_ALERT, DELETE_ALERT } from './queries';
import { ROLE_NAMES } from 'const';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(2.5)
        }
    },
    button: {
        minWidth: 0,
        height: '50px',
        width: '50px',
        padding: 0,
        '& .MuiSvgIcon-root': {
            maxWidth: '14px'
        }
    },
    badge: {
        fontSize: '0.875rem',
        backgroundColor: theme.palette.text.hover,
        width: '22px',
        height: '22px'
    },
    paper: {
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: '10px',
        overflow: 'visible',
        marginTop: theme.spacing(1.375),
        maxWidth: '840px',
        '&::before': {
            content: "''",
            position: 'absolute',
            top: '-8px',
            left: '50%',
            borderBottom: '8px solid white',
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            transform: 'translate(-50%, 0)'
        },
        '&::after': {
            content: "''",
            zIndex: '-1',
            position: 'absolute',
            top: '-9px',
            left: '50%',
            borderBottom: `9px solid ${theme.palette.primary.light}`,
            borderLeft: '9px solid transparent',
            borderRight: '9px solid transparent',
            transform: 'translate(-50%, 0)'
        }
    },
    menuList: {
        padding: 0,
        borderRadius: 'inherit',
        display: 'flex',
        flexFlow: 'column nowrap',
        overflowY: 'auto',
        maxHeight: '50vh'
    },
    menuItem: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'flex-start',
        padding: theme.spacing(2, 8, 2, 6),
        margin: theme.spacing(0, 0, -0.125),
        whiteSpace: 'normal',
        '&::after': {
            content: '""',
            borderBottom: `1px solid ${theme.palette.divider}`,
            position: 'absolute',
            bottom: 0,
            right: theme.spacing(3.75),
            left: theme.spacing(6)
        },
        '&:hover': {
            '& $notificationHeading': {
                color: theme.palette.primary.main
            },
            '& $notificationText': {
                color: theme.palette.primary.main
            }
        },
        '&:last-child': {
            border: 0,
            '&::after': {
                content: 'none'
            }
        }
    },
    menuItemEmpty: {
        margin: '0',
        paddingLeft: theme.spacing(3.75),
        '&::after': {
            left: theme.spacing(3.75)
        }
    },
    readStatus: {
        width: '9px',
        height: '9px',
        border: `2px solid ${theme.palette.divider}`,
        borderRadius: '50%',
        position: 'absolute',
        left: `${theme.spacing(2.75)}px`,
        top: `${theme.spacing(4)}px`
    },
    unread: {
        backgroundColor: theme.palette.text.hover,
        border: `2px solid ${theme.palette.text.hover}`
    },
    notificationHeading: {
        fontWeight: 500,
        fontSize: '0.9375rem',
        lineHeight: '1.6',
        color: theme.palette.secondary.contrastText
    },
    notificationText: {
        fontSize: '0.875rem',
        lineHeight: '1.6',
        marginTop: `${theme.spacing(0.5)}px`,
        color: theme.palette.text.primary
    },
    notificationCloseButton: {
        position: 'absolute',
        top: '20px',
        right: '1.5rem'
    },
    readMore: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontSize: '0.9375rem',
        fontWeight: '500',
        margin: `${theme.spacing(1.125)}px ${theme.spacing(3.75)}px ${theme.spacing(1.125)}px auto`,
        paddingLeft: `${theme.spacing(3.75)}px`,
        transition: 'color 200ms ease',
        '& .MuiSvgIcon-root': {
            margin: `0 0 0 ${theme.spacing(1)}px`
        },
        '&:hover': {
            color: theme.palette.text.hover
        }
    }
}));

const SearchAlerts = (props) => {
    const { showReadMore, closeNotifications } = props;

    const { t } = useTranslation();
    const classes = useStyles();

    // States
    const [anchorEl, setAnchorEl] = useState(null);
    const [unread, setUnread] = useState(0);
    const currentRole = useSelector((state) => state.auth.role);
    const [notifications, setNotifications] = useState([]);

    // Queries
    const { data = {} } = useQuery(TRIGGER_RESULTS);
    const { triggerResults = [] } = data;

    // Other vars
    const vacancyLink = currentRole === ROLE_NAMES.HR_USER ? '/hr-kansen' : '/mijn-kansen';

    // Mutations
    const [updateAlert] = useMutation(UPDATE_ALERT, {
        refetchQueries: ['TriggerResults']
    });

    const [deleteAlert] = useMutation(DELETE_ALERT, {
        refetchQueries: ['TriggerResults']
    });

    // Effects
    useEffect(() => {
        let _unread = 0;
        setNotifications([]);
        triggerResults.forEach((alert) => {
            if (!alert.viewDate) {
                _unread = _unread + 1;
            }
            setNotifications((prevState) => [...prevState, alert]);
        });
        setUnread(_unread);
    }, [triggerResults]);

    useEffect(() => {
        setNotifications(notifications);
    }, [notifications]);

    // Functions
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (notification) => () => {
        setAnchorEl(null);
        if (notification && notification.id) {
            updateAlert({
                variables: {
                    input: {
                        id: notification.id
                    }
                }
            })
                .then((r) => console.log('result', r))
                .catch((e) => console.log('e', e));
        }
    };

    const handleCloseNotification = (event, notification) => {
        event.preventDefault();
        event.stopPropagation();
        deleteAlert({
            variables: {
                input: {
                    id: notification.id
                }
            }
        })
            .then((r) => console.log('result', r))
            .catch((e) => console.log('e', e));
    };

    const [externalNotificationTypes, setExternalNotificationTypes] = useState([]);
    useEffect(() => {
        if (process.env.REACT_APP_MY_FLOW_DOMAIN.includes(window.location.host)) {
            setExternalNotificationTypes([
                'SearchTriggerResult',
                'HrUserFirstVacancyTriggerResult',
                'HrUserVacancyTriggerResult',
                'ProfileTriggerResult',
                'ProfileCompletionTriggerResult'
            ]);
        } else {
            setExternalNotificationTypes([
                'CareerTestTriggerResult',
                'NameNotSetTriggerResult',
                'QuickScanTriggerResult',
                'AdvisorMeetingTriggerResult'
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.host]);

    const platformLink = {
        mijnFlow: process.env.REACT_APP_MY_FLOW_DOMAIN,
        kansenPortal: process.env.REACT_APP_VACANCIES_FLOW_DOMAIN
    };

    const getNotificationLink = (alert) => {
        const { vacancyId, title, type } = alert;

        switch (type) {
            case 'HrUserVacancyTriggerResult':
                return !externalNotificationTypes.includes(type)
                    ? '/hr-kansen/nieuwe-kans'
                    : `${platformLink.kansenPortal}/hr-kansen/nieuwe-kans${currentRole ? `?role=${currentRole}` : ''}`;
            case 'QuickScanTriggerResult':
                return !externalNotificationTypes.includes(type)
                    ? '/quick-scan'
                    : `${platformLink.mijnFlow}/quick-scan${currentRole ? `?role=${currentRole}` : ''}`;
            case 'HrUserFirstVacancyTriggerResult':
                return !externalNotificationTypes.includes(type)
                    ? '/hr-kansen/nieuwe-kans'
                    : `${platformLink.kansenPortal}/hr-kansen/nieuwe-kans${currentRole ? `?role=${currentRole}` : ''}`;
            case 'SearchTriggerResult':
                return !externalNotificationTypes.includes(type)
                    ? `/${vacancyId}/${convertToSlug(title)}`
                    : `${platformLink.kansenPortal}/${vacancyId}/${convertToSlug(title)}${
                          currentRole ? `?role=${currentRole}` : ''
                      }`;
            case 'CareerTestTriggerResult':
                return !externalNotificationTypes.includes(type)
                    ? '/mijn-loopbaanwijzer'
                    : `${platformLink.mijnFlow}/mijn-loopbaanwijzer${currentRole ? `?role=${currentRole}` : ''}`;
            case 'ProfileTriggerResult':
                return !externalNotificationTypes.includes(type)
                    ? '/mijn-gegevens'
                    : `${platformLink.kansenPortal}/mijn-gegevens${currentRole ? `?role=${currentRole}` : ''}`;
            case 'ProfileCompletionTriggerResult':
                return !externalNotificationTypes.includes(type)
                    ? '/mijn-gegevens'
                    : `${platformLink.kansenPortal}/mijn-gegevens${currentRole ? `?role=${currentRole}` : ''}`;
            case 'NameNotSetTriggerResult':
                return !externalNotificationTypes.includes(type)
                    ? '/mijn-gegevens'
                    : `${platformLink.mijnFlow}/mijn-gegevens${currentRole ? `?role=${currentRole}` : ''}`;
            case 'AdvisorMeetingTriggerResult':
                return !externalNotificationTypes.includes(type)
                    ? '/mijn-loopbaanwijzer/gesprek-plannen'
                    : `${platformLink.mijnFlow}/mijn-loopbaanwijzer/gesprek-plannen${
                          currentRole ? `?role=${currentRole}` : ''
                      }`;
            default:
                return console.warn(`${type} has no link defined`);
        }
    };

    return (
        <div className={classes.root}>
            <Badge badgeContent={unread} color="primary" overlap="circle" classes={{ badge: classes.badge }}>
                <Button
                    color="primary"
                    variant="outlined"
                    className={classes.button}
                    iconLeft={() => <Notification color="inherit" />}
                    onClick={handleClick}
                />
                <Popover
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    classes={{
                        paper: classes.paper
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <div className={classes.menuList}>
                        {notifications.length > 0 ? (
                            notifications.map((notification, idx) => {
                                const notificationLinkProps = {
                                    ...(externalNotificationTypes.includes(notification.type) && {
                                        component: 'a',
                                        target: '_blank',
                                        href: getNotificationLink(notification)
                                    }),
                                    ...(!externalNotificationTypes.includes(notification.type) && {
                                        component: RouterLink,
                                        target: '_self',
                                        to: getNotificationLink(notification)
                                    })
                                };

                                return (
                                    <ListItem
                                        className={classes.menuItem}
                                        key={idx}
                                        onClick={handleMenuItemClick(notification)}
                                        {...notificationLinkProps}
                                    >
                                        {closeNotifications && (
                                            <IconButton
                                                size="small"
                                                className={classes.notificationCloseButton}
                                                onClickCapture={(ev) => handleCloseNotification(ev, notification)}
                                                title="Sluit notificatie"
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        )}
                                        <div
                                            className={classNames(classes.readStatus, {
                                                [classes.unread]: !notification.viewDate
                                            })}
                                        ></div>
                                        <Typography className={classes.notificationHeading}>
                                            {notification.title}
                                        </Typography>
                                        <Typography className={classes.notificationText}>
                                            {notification.body}
                                        </Typography>
                                    </ListItem>
                                );
                            })
                        ) : (
                            <ListItem className={classNames(classes.menuItem, classes.menuItemEmpty)}>
                                <Typography className={classes.notificationText}>Geen nieuwe alerts</Typography>
                            </ListItem>
                        )}
                    </div>
                    {showReadMore && (
                        <RouterLink to={vacancyLink} className={classes.readMore}>
                            {t('searchAlerts.readMore')}
                            <ArrowRight color="inherit" />
                        </RouterLink>
                    )}
                </Popover>
            </Badge>
        </div>
    );
};

export default SearchAlerts;
