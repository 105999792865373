import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { CtaBlocks, ProfileChecklist, SearchBar, VacancyList } from 'shared';
import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import ProfileInfo from './ProfileInfo';
import { GET_REGIO_LOGO } from './queries';
import { useDispatch } from 'react-redux';
import { setUserRole, logoutUser } from 'store/actions';
import { ROLE_NAMES } from 'const';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1300px',
        width: '100%',
        margin: '0 auto',
        paddingBottom: `${theme.spacing(1.875)}px`,
        [theme.breakpoints.up('sm')]: {
            paddingBottom: `${theme.spacing(12.5)}px`
        }
    },
    title: {
        width: '100%',
        textAlign: 'center',
        marginTop: `${theme.spacing(8)}px`,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2)
        },
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'center'
        }
    },
    regionLogo: {
        maxWidth: '35vw',
        margin: theme.spacing(2, 0, 0),
        [theme.breakpoints.up('sm')]: {
            maxWidth: '200px'
        },
        [theme.breakpoints.up('md')]: {
            margin: 0,
            width: '180px',
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)'
        }
    },
    vacancyContainer: {
        padding: '50px'
    },
    gridItem: {
        flexGrow: 1
    }
}));

const Dashboard = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const currentRole = useSelector((state) => state.auth.role);

    const [width, setWidth] = React.useState(window.innerWidth);

    const token = useSelector((state) => state.auth.token);
    const loggedIn = useSelector((state) => state.auth.isLoggedIn);
    const userId = useSelector((state) => state.auth.userId);
    const roles = useSelector((state) => state.auth.roles);
    const isHrUser = useSelector((state) => state.auth.roles.includes(ROLE_NAMES.HR_USER));
    const isCareerAdvisor = useSelector((state) => state.auth.roles.includes(ROLE_NAMES.CAREER_ADVISOR));
    const isDiAdvisor = useSelector((state) => state.auth.roles.includes(ROLE_NAMES.DI_ADVISOR));
    const isUser = useSelector((state) => state.auth.roles.includes(ROLE_NAMES.USER));

    const [getLogo, { loading, data }] = useLazyQuery(GET_REGIO_LOGO);

    const ctaData = {
        title: 'Ook interessant',
        items: [
            {
                id: 1,
                color: 'primary',
                text: 'Werk aan Wonen',
                hoverText: 'Bekijk vacatures, en vind alles over arbeidsvoorwaarden, salaris en meer.',
                href: 'https://www.werkaanwonen.nl/',
                external: true
            },
            {
                id: 2,
                color: 'secondary',
                text: 'Mijn plan voor morgen',
                hoverText: 'Meebewegen met je werk begint met een goed plan. ',
                href: `${process.env.REACT_APP_TOMORROW_FLOW_DOMAIN}/planvoormorgen`,
                external: true
            },
            {
                id: 3,
                color: 'tertiary',
                text: 'Loopbaanwijzer',
                hoverText: 'Weten wat je kunt en wilt. Maak die stap.',
                href: `${process.env.REACT_APP_MY_FLOW_DOMAIN}${currentRole ? `?role=${currentRole}` : ''}`,
                external: true
            },
            {
                id: 4,
                color: 'quaternary',
                text: "Regio's",
                hoverText: 'Maak jij al deel uit van een actieve regio?',
                href: `${process.env.REACT_APP_FLOW_WEB_DOMAIN}/flow/in-je-regio`,
                external: true
            }
        ]
    };

    useEffect(() => {
        window.addEventListener('resize', updateWidthAndHeight);
        return () => window.removeEventListener('resize', updateWidthAndHeight);
    });

    useEffect(() => {
        if (width) {
            setWidth(width);
        }
    }, [width]);

    useEffect(() => {
        if (userId) {
            getLogo({
                variables: {
                    id: userId
                }
            });
        }
    }, [getLogo, userId]);

    useEffect(() => {
        // Set the role from the cookie. This is required here as the Dashboard is not a private route, so this will not have been done by the PrivateRoute's handling.

        // First, parse the token to obtain the available roles
        if (token && token !== 'deleted') {
            const decodeToken = jwtDecode(token);
            const date = new Date();
            const expireDate = new Date(decodeToken.exp);

            if (expireDate < date) {
                dispatch(logoutUser);
                history.push('/login');
                return;
            }
            const userRoles = decodeToken?.roles?.length ? decodeToken.roles : [ROLE_NAMES.USER];

            // Check whether the role selected in the cookie is still available, and if so, set it to the state
            const cookieRole = Cookie.get('roleSelect');
            const roleFromUser = userRoles.find((role) => role === cookieRole) || roles[0] || 0;
            if (!currentRole || currentRole !== roleFromUser) {
                console.log(`Setting role from '${currentRole}' to '${roleFromUser}`);
                dispatch(setUserRole(roleFromUser));
            }
        }
    }, [token, currentRole, dispatch, roles, history]);

    useEffect(() => {
        if (isHrUser && roles.length < 2) {
            history.push('/hr');
        } else if (currentRole === ROLE_NAMES.HR_USER) {
            history.push('/hr');
        }
        // eslint-disable-next-line
    }, [isCareerAdvisor, isDiAdvisor, history, isHrUser, isUser, currentRole]);

    //functions
    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
    };

    const handleSearch = (obj) => {
        let searchString = `page=0&pageSize=10`;
        if (obj.distance) {
            searchString += `&distance=${obj.distance}`;
        }
        if (obj.city && obj.city.id) {
            searchString += `&city=${obj.city.id}`;
        }
        if (obj.search) {
            searchString += `&search=${obj.search}`;
        }

        history.push(`/kansen?${searchString}`);
    };

    return (
        <Grid container className={classes.root} spacing={8} justify="space-between">
            <Grid item xs={12}>
                <Typography className={classes.title} variant="h2">
                    {t('dashboard.title')}

                    {!loading && data && data.user && data.user.corporation && data.user.corporation.region.logo && (
                        <React.Fragment>
                            <img
                                alt={data.user.corporation.region.logo.name}
                                src={data.user.corporation.region.logo.url}
                                className={classes.regionLogo}
                            />
                        </React.Fragment>
                    )}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <SearchBar onChange={handleSearch} loggedIn={loggedIn} />
            </Grid>
            <Grid item md={12} lg={6} classes={{ root: classes.gridItem }}>
                {width <= 1366 ? <VacancyList /> : loggedIn ? <ProfileChecklist /> : <ProfileInfo />}
            </Grid>
            <Grid item md={12} lg={6} classes={{ root: classes.gridItem }}>
                {width <= 1366 ? loggedIn ? <ProfileChecklist /> : <ProfileInfo /> : <VacancyList />}
            </Grid>
            <Grid item xs={12}>
                <CtaBlocks data={ctaData} />
            </Grid>
        </Grid>
    );
};

export default Dashboard;
