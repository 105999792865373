import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import merge from 'deepmerge';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Tooltip } from 'shared';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Typography,
    Paper,
    Box,
    Avatar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from '@material-ui/core';
import { PersonOutline } from '@material-ui/icons';

import { LoaderModal, SubMenu, FavoriteButton } from 'shared';
import { RichTextContainer, Breadcrumbs, Button } from 'shared';

import { GET_USER, GET_MY_USERS, RESPOND_TO_USER } from '../queries';
import { convertToSlug } from 'helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.secondary,
        [theme.breakpoints.up('md')]: {
            paddingTop: '60px'
        }
    },
    grid: {
        maxWidth: '1200px',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: theme.spacing(0, 2)
        }
    },
    paper: {
        width: '100%',
        padding: `${theme.spacing(6)}px`,
        borderRadius: '25px',
        marginBottom: `${theme.spacing(2.5)}px`
    },
    paperAside: {
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(9)
        }
    },
    heading: {
        ...theme.typography.h6,
        fontWeight: 600,
        paddingBottom: `${theme.spacing(2.5)}px`,
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: `${theme.spacing(2.5)}px`
    },
    list: {},
    item: {
        border: `1px solid ${theme.palette.borderColor.main}`,
        borderRadius: '35px',
        color: theme.palette.text.secondary,
        fontSize: '1em',
        fontWeight: 500,
        margin: theme.spacing(0, 1.25, 1.25, 0),
        padding: `${theme.spacing(0.375)}px ${theme.spacing(1.75)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 2),
            lineHeight: '1.1'
        }
    },
    recent: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap'
    },
    bigAvatar: {
        width: '185px',
        height: '185px',
        border: '5px solid ' + theme.palette.common.white,
        boxShadow: theme.shadows[2],
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            top: '-120px'
        }
    },
    avatar: {
        position: 'relative',
        width: '185px',
        height: '72px',
        marginRight: `${theme.spacing(3.125)}px`,
        [theme.breakpoints.down('sm')]: {
            margin: `-${theme.spacing(8)}px auto ${theme.spacing(2)}px`,
            order: '-1',
            height: 'auto'
        }
    },
    flex: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '73px',
        flexFlow: 'column nowrap',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            flexFlow: 'row wrap',
            textAlign: 'left'
        }
    },
    button: {
        marginBottom: `${theme.spacing(2.5)}px`,
        fontSize: '1em'
    },
    buttonWrap: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    itemRow: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        paddingBottom: `${theme.spacing(2.5)}px`,
        marginBottom: `${theme.spacing(2.5)}px`,
        '&:last-child': {
            borderBottom: 0,
            marginBottom: 0,
            paddingBottom: 0
        }
    }
}));

const CandidateDetail = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { id } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [motivation, setMotivation] = useState(null);
    const [liked, setLiked] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [draft, setDraft] = useState({
        corporation: {
            name: ''
        },
        profile: {
            firstName: '',
            lastName: '',
            middleName: '',
            dateOfBirth: '',
            city: {
                name: ''
            },
            educationGrade: '',
            position: '',
            salaryScale: '',
            workingHoursPerWeek: 'Onbekend',
            region: {
                name: ''
            },
            skillList: [],
            expertiseList: [],
            preferredExpertiseList: [],
            workExperience: [],
            educations: [],
            motivation: '',
            biography: '',
            PreferredPosition: '',
            specialties: []
        }
    });

    const { loading, data = {} } = useQuery(GET_USER, {
        variables: {
            id: id
        },
        fetchPolicy: 'no-cache'
    });
    const { loading: loadingCurrentUser, data: currentUserData = {} } = useQuery(GET_MY_USERS, {
        fetchPolicy: 'no-cache'
    });
    const { currentUser = {} } = currentUserData;
    const { likes = [] } = currentUser;

    const [respondToUser] = useMutation(RESPOND_TO_USER);

    const removeEmpty = useCallback((obj) => {
        Object.keys(obj).forEach(function (key) {
            if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
            else if (obj[key] == null) delete obj[key];
        });
    }, []);

    useEffect(() => {
        if (!data) return;
        if (data.user) {
            const dbData = data.user;
            removeEmpty(dbData);
            setDraft((draft) => merge.all([draft, dbData]));
        }
    }, [data, removeEmpty]);

    useEffect(() => {
        if (!loadingCurrentUser && likes && likes.length) {
            if (likes.some((i) => i.id.toString() === id.toString())) {
                setLiked(true);
            }
        }
    }, [loadingCurrentUser, likes, id]);

    const BreadcrumbPath = useMemo(
        () => [
            {
                title: 'Kandidaten',
                link: '/kandidaten'
            },
            {
                title: `${draft.profile.firstName} ${draft.profile.lastName}`,
                link: `/kandidaten/${id}/${convertToSlug(`${draft.profile.firstName} ${draft.profile.lastName}`)}`
            }
        ],
        [draft.profile.firstName, draft.profile.lastName, id]
    );

    const handleResponePopUp = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
        setMotivation(null);
    };

    const handleSendResponse = () => {
        handleClose();
        respondToUser({
            variables: {
                input: {
                    userId: id,
                    message: motivation
                }
            }
        }).then((r) => {
            if (!r.errors) {
                enqueueSnackbar({
                    variant: 'success',
                    message: t('candidateDetail.messageSend')
                });
            } else {
                enqueueSnackbar({
                    variant: 'error',
                    message: 'Er ging iets mis, Bericht niet verzonden'
                });
            }
        });
    };

    const handleLiked = (data) => {
        setLiked(data);
    };

    useEffect(() => {
        history.push('/hr');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SubMenu
                renderRight={() => (
                    <React.Fragment>
                        <FavoriteButton
                            label={liked ? 'Kandidaat verwijderen' : t('candidateDetail.save')}
                            success={t('candidateDetail.saveSucces')}
                            id={id}
                            onChange={handleLiked}
                            liked={liked}
                            type="user"
                        />
                        <Button
                            variant="contained"
                            customColor="yellow"
                            label={t('candidateDetail.approach')}
                            onClick={handleResponePopUp}
                            iconLeft={() => <PersonOutline />}
                        />
                    </React.Fragment>
                )}
            />
            <Breadcrumbs path={BreadcrumbPath} />
            <div className={classes.root}>
                <Grid className={classes.grid} container spacing={2} justify="center" alignItems="flex-start">
                    <Grid item xs={12} md={9}>
                        <Paper elevation={0} className={classNames(classes.paper, classes.flex)}>
                            <div>
                                <Typography className={classes.title} variant="h3">
                                    {`${draft.profile.firstName} ${draft.profile.lastName}`}
                                </Typography>
                                <Typography className={classes.title} variant="h6">
                                    {draft.profile.position}
                                </Typography>
                            </div>
                            {draft.profile.profilePicture && (
                                <div className={classes.avatar}>
                                    <Avatar
                                        alt={draft.profile.profilePicture.name}
                                        src={draft.profile.profilePicture.url}
                                        className={classes.bigAvatar}
                                    />
                                </div>
                            )}
                        </Paper>
                        <Paper elevation={0} className={classes.paper}>
                            <Typography className={classes.heading}>{t('userProfile.titles.biography')}</Typography>
                            <RichTextContainer content={draft.profile.biography} />
                        </Paper>
                        <Paper elevation={0} className={classes.paper}>
                            <Typography className={classes.heading}>{t('userProfile.titles.expertise')}</Typography>
                            <div className={classes.recent}>
                                {draft.profile.expertiseList &&
                                    draft.profile.expertiseList.map((item) => (
                                        <Typography key={item.id} className={classes.item}>
                                            {item.description}
                                        </Typography>
                                    ))}
                            </div>
                        </Paper>
                        <Paper elevation={0} className={classes.paper}>
                            <Typography className={classes.heading}>
                                {t('userProfile.titles.preferredSituation')}
                            </Typography>
                            <Typography variant="subtitle1">{t('userProfile.titles.PreferredPosition')}</Typography>
                            <Typography variant="body1">{draft.profile.preferredPosition || ''}</Typography>
                            <Box mt={3} />
                            <Typography variant="subtitle1">
                                {t('userProfile.titles.preferredExpertise-subtitle')}
                            </Typography>
                            <Box mt={2} />
                            <div className={classes.recent}>
                                {draft.profile.preferredExpertiseList &&
                                    draft.profile.preferredExpertiseList.map((item) => (
                                        <Typography key={item.id} className={classes.item}>
                                            {item.description}
                                        </Typography>
                                    ))}
                            </div>
                        </Paper>
                        <Paper elevation={0} className={classes.paper}>
                            <Typography className={classes.heading}>{t('userProfile.titles.specialties')}</Typography>
                            <div className={classes.recent}>
                                {draft.profile.specialties &&
                                    draft.profile.specialties.map((item) => (
                                        <Typography key={item.id} className={classes.item}>
                                            {item.description}
                                            {item.explanation && <Tooltip title={item.explanation} />}
                                        </Typography>
                                    ))}
                            </div>
                        </Paper>
                        <Paper elevation={0} className={classes.paper}>
                            <Typography className={classes.heading}>{t('userProfile.titles.courses')}</Typography>
                            {draft.profile.educations &&
                                draft.profile.educations.map((item) => (
                                    <div key={item.id} className={classes.itemRow}>
                                        <div>
                                            <Typography variant="subtitle1">{item.direction}</Typography>
                                            <Typography variant="body1">{item.institution}</Typography>
                                            <Typography variant="body1">{item.title}</Typography>
                                        </div>
                                        <div>
                                            <Typography variant="body2">
                                                {moment(item.startYear).format('YYYY')}
                                                {' - '}
                                                {moment(item.endYear).format('YYYY')}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                        </Paper>

                        <Paper elevation={0} className={classes.paper}>
                            <Typography className={classes.heading}>{t('userProfile.titles.experience')}</Typography>
                            {draft.profile.workExperience &&
                                draft.profile.workExperience.map((item) => (
                                    <div key={item.id} className={classes.itemRow}>
                                        <div>
                                            <Typography variant="subtitle1">{item.position.description}</Typography>
                                            <Typography variant="body1">{item.company}</Typography>
                                            <Typography variant="body1">{item.city ? item.city.name : ''}</Typography>
                                        </div>
                                        <div>
                                            <Typography variant="body2">
                                                {moment(item.startYear).format('YYYY')}
                                                {' - '}
                                                {moment(item.endYear).format('YYYY')}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                        </Paper>
                        <Paper elevation={0} className={classes.paper}>
                            <Typography className={classes.heading}>{t('userProfile.titles.skills')}</Typography>
                            <div className={classes.recent}>
                                {draft.profile.skillList &&
                                    draft.profile.skillList.map((item) => (
                                        <Typography key={item.id} className={classes.item}>
                                            {item.description}
                                        </Typography>
                                    ))}
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Grid item xs={12}>
                            <Paper elevation={0} className={classNames(classes.paper, classes.paperAside)}>
                                <Typography className={classes.heading}>{t('userProfile.titles.situation')}</Typography>
                                <Typography variant="subtitle1">{t('userProfile.titles.city')}</Typography>
                                <Typography variant="body1">{draft.profile.city.name}</Typography>
                                <Box mb={1} />
                                <Typography variant="subtitle1">{t('userProfile.titles.region')}</Typography>
                                <Typography variant="body1">{draft.profile.region.description}</Typography>
                                <Box mb={1} />
                                <Typography variant="subtitle1">{t('userProfile.titles.corporation')}</Typography>
                                <Typography variant="body1">{draft.corporation.name}</Typography>
                                <Box mb={1} />
                                <Typography variant="subtitle1">{t('userProfile.titles.workHours')}</Typography>
                                <Typography variant="body1">
                                    {draft.profile.workingHoursPerWeek
                                        ? draft.profile.workingHoursPerWeek.value || ''
                                        : ''}
                                </Typography>
                                <Box mb={1} />
                                <Typography variant="subtitle1">{t('userProfile.titles.educationLevel')}</Typography>
                                <Typography variant="body1">{draft.profile.educationGrade.description}</Typography>
                                <Box mb={1} />
                                <Typography variant="subtitle1">{t('userProfile.titles.salaryScale')}</Typography>
                                <Typography variant="body1">
                                    {draft.profile.salaryScale ? draft.profile.salaryScale.description || '' : ''}
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <LoaderModal loading={loading || loadingCurrentUser} />
                <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{t('candidateDetail.approach')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{t('candidateDetail.dialogText')}</DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="motivation"
                            label={t('candidateDetail.dialogMessage')}
                            type="email"
                            fullWidth
                            multiline
                            rows="8"
                            onBlur={(event) => setMotivation(event.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" label={t('candidateDetail.dialogCancel')} />
                        <Button
                            onClick={handleSendResponse}
                            variant="contained"
                            label={t('candidateDetail.dialogSend')}
                            color="primary"
                        />
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

export default CandidateDetail;
