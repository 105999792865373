import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import { Block } from 'shared';

const useStyles = makeStyles((theme) => ({
    list: {
        marginBottom: '30px',
        marginTop: `${theme.spacing(2)}px`
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px'
        }
    },
    percentage: {
        color: theme.palette.primary.main,
        opacity: 0.5
    },
    progress: (props) => ({
        height: '10px',
        width: '100%',
        margin: `${theme.spacing(3)}px ${theme.spacing(0)}px`,
        background: theme.palette.background.primary,
        borderRadius: '5px',
        position: 'relative',
        '&:after': {
            width: `${props.percentage}%`,
            position: 'absolute',
            borderRadius: '5px',
            top: 0,
            left: 0,
            height: '10px',
            display: 'block',
            content: '" "',
            background: theme.palette.secondary.main
        }
    }),
    checkbox: {
        marginLeft: 0,
        marginBottom: `${theme.spacing(4)}px`
    },
    readMore: {
        alignSelf: 'flex-end'
    },
    block: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4)
        }
    },
    loader: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        background: 'rgba(255,255,255,  0.6)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '25px'
    }
}));

const ProfileChecklist = () => {
    const classes = useStyles({ percentage: 0 });
    return (
        <Block className={classes.block} variant="white">
            <Typography className={classes.title} variant="h5">
                Het Kansenportaal
            </Typography>
            <br />
            <Typography className={classes.title} variant="h6">
                Zoek je een vacature? Of een stage of werkervaringsplaats? FLOW helpt je daarbij.
            </Typography>
            <br />
            <Typography variant="body1">
                Op het Kansenportaal van FLOW kun je diverse kansen vinden: vacatures, stages, inspiratieplekken en
                tijdelijke opdrachten. Bij elke kans kun je (rechts)boven doorklikken naar de site van de corporatie.
            </Typography>

            <Typography variant="body1">
                <br />
                Wist je dat je kansen en zoekopdrachten kunt bewaren als je bent ingelogd? Maak ook eens een kansenalert
                aan, zodat je een seintje krijgt als er een vacature van jouw keuze is geplaatst.
            </Typography>
        </Block>
    );
};

export default ProfileChecklist;
