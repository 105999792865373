import moment from 'moment';
import 'moment/locale/nl';
moment.locale('nl');

export function isEmpty(obj) {
    for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            return false;
        }
    }
    return true;
}

export function formatDate(date) {
    const newDate = moment(date).format('D MMMM Y');

    return newDate;
}

export function convertToSlug(name) {
    if (!name) return '';
    return name
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
}

export function mapToSelect(array, label, value) {
    if (!array) return;

    let newArray = array.map((item) => {
        return {
            label: item[label],
            value: item[value]
        };
    });

    return newArray;
}

export const isUndefinedOrNull = (value) => value === undefined || value === null;
export const isUndefinedOrNullOrEmptyString = (value) => isUndefinedOrNull(value) || value === '';
