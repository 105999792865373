import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { getEnv } from '../../helpers';

const GTMLayer = (props) => {
    const { children, eventName = 'virtualPageView', dataLayerName = 'PageDataLayer' } = props;
    const location = useLocation();
    const gtmId = getEnv('REACT_APP_GTM_ID');

    useEffect(() => {
        if (!gtmId) {
            return;
        }
        if (location && location.pathname) {
            const tagManagerArgs = {
                dataLayer: {
                    event: eventName,
                    page: location.pathname
                },
                dataLayerName: dataLayerName
            };

            TagManager.dataLayer(tagManagerArgs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return <React.Fragment>{children}</React.Fragment>;
};

export default GTMLayer;
