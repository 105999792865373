import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { CandidateItem, FilterOverview, CandidateSearchBar } from 'shared';
import { useFilter } from 'hooks';
import FilterContent from './FilterContent';
import { Container } from '@material-ui/core';
import { GET_USERS } from '../queries';
import { useLazyQuery } from '@apollo/client';
import { convertToSlug } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    CandidatesearchBar: {
        backgroundColor: theme.palette.background.primaryContrast
    },
    wrapper: {
        maxWidth: '1400px'
    },
    overview: {
        padding: `${theme.spacing(6)}px 0`,
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5)
        }
    }
}));

const Candidates = () => {
    const classes = useStyles();
    const history = useHistory();
    const query = useFilter({ filter: { profilePublic: true } });
    const [currentSearchValue, setCurrentSearchValue] = useState({});
    const [getUsers, { loading, data = {}, error }] = useLazyQuery(GET_USERS);
    const { t } = useTranslation();

    const [width, setWidth] = React.useState(window.innerWidth);
    const [showFilters, setShowFilters] = useState(true);

    useEffect(() => {
        width >= 960 ? setShowFilters(true) : setShowFilters(false);
    }, [width]);

    useEffect(() => {
        window.addEventListener('resize', updateWidthAndHeight);
        return () => window.removeEventListener('resize', updateWidthAndHeight);
    });

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        getUsers({
            variables: { ...query.query }
        });
    }, [getUsers, query.query]);

    const { users = {} } = data;

    const likedUsers = useSelector((state) => state.auth.user.likes);

    useEffect(() => {
        if (error) {
            console.log('Error for snackbar', error);
        }
    }, [error]);

    const handleSearch = ({ search, region }) => {
        query.setQuery({
            filter: {
                region: region ? region : null,
                search: search ? search : null
            }
        });
    };

    const setValue = (val) => {
        setCurrentSearchValue(val);
    };

    const handleFilterClick = () => {
        setShowFilters((prevState) => !prevState);
    };

    useEffect(() => {
        history.push('/hr');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <div className={classes.CandidatesearchBar}>
                <Container className={classes.wrapper}>
                    <CandidateSearchBar
                        onChange={handleSearch}
                        variant="compact"
                        initialValue={query.filters || {}}
                        loading={loading}
                        currentValue={setValue}
                        filterClick={handleFilterClick}
                        hasFilters={width < 960 ? true : false}
                        accordion={width < 960 ? true : false}
                    />
                </Container>
            </div>

            <FilterOverview
                loading={loading}
                query={query}
                count={users.count}
                currentSearchValue={currentSearchValue}
                filterContent={FilterContent}
                title="Verfijn zoekresultaten"
                className={classes.overview}
                showFilters={showFilters}
            >
                {users && users.users && users.users.length
                    ? users.users.map((user) => (
                          <CandidateItem
                              key={user.id}
                              id={user.id}
                              format={true}
                              title={`${user.profile.firstName} ${user.profile.lastName}`}
                              link={`/kandidaten/${user.id}/${convertToSlug(
                                  `${user.profile.firstName} ${user.profile.lastName}`
                              )}`}
                              place={user.profile.city ? user.profile.city.name : ''}
                              hours={
                                  user.profile.workingHoursPerWeek ? user.profile.workingHoursPerWeek.value || '' : ''
                              }
                              approached={user.approached}
                              introduction={user.profile.biography}
                              profilePicture={user.profile.profilePicture ? user.profile.profilePicture.url : ''}
                              liked={
                                  likedUsers
                                      ? likedUsers.filter((item) => {
                                            return item.id === user.id ? true : false;
                                        }).length > 0
                                          ? true
                                          : false
                                      : false
                              }
                          />
                      ))
                    : !loading && t('candidates.noResults')}
            </FilterOverview>
        </React.Fragment>
    );
};

export default Candidates;
