import React, { useEffect, useReducer } from 'react';
import { useQuery } from '@apollo/client';
import { FormGroup } from '@material-ui/core';

import { Checkbox, ExpansionPanel } from 'shared';
import { GET_EXPERTISES, GET_REGIONS, GET_EDUCATION, GET_VACANCY_TYPES } from '../../queries';

const Filtercontent = (props) => {
    const { loading = false, onChange = false, filters = {} } = props;

    const [expanded, setExpanded] = useReducer((state, action) => {
        return { ...state, ...action };
    }, {});

    const {
        expertiseList: expertiseListFilter = [],
        regions: regionsDataFilter = [],
        educationGrade: educationsDataFilter = [],
        types: typesDataFilter = []
    } = filters;

    const expertiseList = Array.isArray(expertiseListFilter) ? expertiseListFilter : [expertiseListFilter];
    const regions = Array.isArray(regionsDataFilter) ? regionsDataFilter : [regionsDataFilter];
    const educations = Array.isArray(educationsDataFilter) ? educationsDataFilter : [educationsDataFilter];
    const types = Array.isArray(typesDataFilter) ? typesDataFilter : [typesDataFilter];

    const { loading: loadingExpertises, data: expertisesData } = useQuery(GET_EXPERTISES);
    const { loading: loadingRegions, data: regionsData } = useQuery(GET_REGIONS);
    const { loading: loadingEducation, data: educationData } = useQuery(GET_EDUCATION);
    const { loading: loadingTypes, data: typesData } = useQuery(GET_VACANCY_TYPES);

    useEffect(() => {
        if (!loading) {
            const obj = {};
            Object.keys(filters).forEach((key) => {
                if (filters[key] && filters[key].length) obj[key] = true;
            });
            setExpanded({ ...obj });
        }
    }, [loading, filters]);

    return (
        <React.Fragment>
            <ExpansionPanel title="Vakgebied" expanded={expanded.expertiseList || false}>
                <FormGroup>
                    {!loadingExpertises &&
                        expertisesData &&
                        expertisesData.expertises.expertises.map((expertise) => (
                            <Checkbox
                                key={expertise.id}
                                label={expertise.description}
                                onChange={onChange}
                                name={expertise.id}
                                arrayName="expertiseList"
                                loading={loading || loadingExpertises}
                                checked={expertiseList.some((i) => i.toString() === expertise.id.toString())}
                            />
                        ))}
                </FormGroup>
            </ExpansionPanel>
            <ExpansionPanel title="Regio" expanded={expanded.regions || false}>
                <FormGroup>
                    {!loadingRegions &&
                        regionsData &&
                        regionsData.regions.regions.map((region) => (
                            <Checkbox
                                key={region.id}
                                label={region.description}
                                onChange={onChange}
                                name={region.id}
                                arrayName="specialties"
                                loading={loading}
                                checked={regions.some((i) => i.toString() === region.id.toString())}
                            />
                        ))}
                </FormGroup>
            </ExpansionPanel>
            <ExpansionPanel title="Opleidingsniveau" expanded={expanded.educationGrades || false}>
                <FormGroup>
                    {!loadingEducation &&
                        educationData &&
                        educationData.educationGrades.educationGrades.map((educationGrade) => (
                            <Checkbox
                                key={educationGrade.id}
                                label={educationGrade.description}
                                onChange={onChange}
                                name={educationGrade.id}
                                arrayName="educationGrade"
                                loading={loading}
                                checked={educations.some((i) => i.toString() === educationGrade.id.toString())}
                            />
                        ))}
                </FormGroup>
            </ExpansionPanel>
            <ExpansionPanel title="Bijzonderheden" expanded={expanded.types || false}>
                <FormGroup>
                    {!loadingTypes &&
                        typesData &&
                        typesData.paginatedVacancyTypes.types.map(
                            (type) =>
                                type.isAvailableAsSpecialty && (
                                    <Checkbox
                                        key={type.id}
                                        label={type.description}
                                        onChange={onChange}
                                        name={type.id}
                                        arrayName="specialties"
                                        loading={loading}
                                        checked={types.some((i) => i.toString() === type.id.toString())}
                                    />
                                )
                        )}
                </FormGroup>
            </ExpansionPanel>
        </React.Fragment>
    );
};

export default Filtercontent;
