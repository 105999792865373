import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    mobile: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    link: {
        fontSize: '1.4rem',
        textDecoration: 'none',
        margin: theme.spacing(1.5, 0),
        padding: theme.spacing(1.5, 0),
        fontWeight: 500,
        lineHeight: '32px',
        position: 'relative',
        overflow: 'initial',
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-2px',
            left: 0,
            height: '4px',
            width: '100%',
            backgroundColor: theme.palette.primary.main,
            opacity: 0,
            transition: 'opacity 300ms ease'
        },
        '&.Mui-selected': {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent'
            },
            '&::after': {
                opacity: 1
            }
        },
        '&:hover': {
            backgroundColor: 'transparent'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.1rem',
            padding: theme.spacing(3, 0),
            margin: 0,
            '&:hover': {
                '&::after': {
                    opacity: 1
                }
            }
        }
    },
    subLink: {
        textDecoration: 'none',
        fontWeight: 500,
        lineHeight: '16px',
        position: 'relative',
        overflow: 'initial',
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        fontSize: '1.1rem',
        padding: theme.spacing(1.5),
        margin: '0!important',
        '&.Mui-selected': {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent'
            }
        },
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.text.hover
        }
    },
    wrapper: {
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                '&> ul': {
                    opacity: 1,
                    visibility: 'visible'
                }
            }
        }
    },
    subMenu: {
        top: '80px',
        position: 'absolute',
        margin: 0,
        opacity: 0,
        visibility: 'hidden',
        display: 'block',
        width: 'auto',
        transform: 'none',
        padding: '15px 35px 15px 15px',
        background: '#fff',
        border: `1px solid ${theme.palette.borderColor.main}`,
        left: 0,
        borderRadius: '0 0 10px 10px',
        boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.06)'
    }
}));

const NestMenuItem = (props) => {
    const { item, onSucces, idx } = props;
    const classes = useStyles();
    const location = useLocation();

    return (
        <div className={classes.wrapper}>
            <MenuItem
                key={idx}
                component={Link}
                className={classes.link}
                to={item.path}
                variant="body1"
                selected={location.pathname === item.path}
                onClick={onSucces}
            >
                {item.linkText}
                <ExpandMoreIcon className={classes.iconSVG} />
            </MenuItem>
            <ul className={classes.subMenu}>
                {item.children &&
                    item.children.map((child, idx) =>
                        child.external ? (
                            <a
                                key={idx}
                                className={classes.subLink}
                                href={child.path}
                                target={'_blank'}
                                rel="noopener noreferrer"
                            >
                                {child.linkText}
                            </a>
                        ) : (
                            <MenuItem
                                key={idx}
                                component={Link}
                                className={classes.subLink}
                                to={child.path}
                                variant="body1"
                                selected={location.pathname === child.path}
                            >
                                {child.linkText}
                            </MenuItem>
                        )
                    )}
            </ul>
        </div>
    );
};

export default NestMenuItem;
