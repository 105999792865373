import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { LoaderModal } from 'shared';
import { InputField, Button } from 'shared';
import { useForm } from 'hooks';
import { FORGOT_PASSWORD } from './queries';
import { useMutation } from '@apollo/client';
import Cookie from 'js-cookie';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1400px',
        width: '100%',
        padding: theme.spacing(0, 3),
        margin: `${theme.spacing(12)}px auto`,

        [theme.breakpoints.down('sm')]: {
            margin: `${theme.spacing(6)}px auto`
        }
    },
    wrapper: {
        maxWidth: '450px'
    },
    input: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: theme.spacing(2.5)
    }
}));

const formSettings = {
    email: {
        required: true,
        format: 'email'
    }
};

const ForgotPassword = () => {
    const { t } = useTranslation();
    const [succesMessage, setSuccesMessage] = useState(false);
    const [form, submitForm] = useForm(formSettings);
    const [forgotPassword, { loading: mutationLoading, data }] = useMutation(FORGOT_PASSWORD);

    const classes = useStyles();

    const handleSubmit = () => {
        setSuccesMessage(false);
        submitForm().then((resp) => {
            if (resp.email) {
                forgotPassword({
                    variables: {
                        input: {
                            email: resp.email
                        }
                    }
                });
            }
        });
    };

    useEffect(() => {
        if (data) {
            if (data.forgotPassword.result) {
                setSuccesMessage(true);
            }
        }
    }, [data]);

    useEffect(() => {
        Cookie.set('popup', true, { expires: 365 });
    }, []);

    const onKeyPress = (e) => {
        if (e.which === 13) {
            handleSubmit(e);
        }
    };

    return (
        <Grid className={classes.root} justify="center" container>
            <Grid className={classes.wrapper} item xs={12} md={4}>
                <Typography variant="h3">{t('forgotPassword.title')}</Typography>
                <Typography variant="body1">{t('forgotPassword.subTitle')}</Typography>
                <InputField
                    className={classes.input}
                    label={t('forgotPassword.emailLabel')}
                    type="email"
                    name="email"
                    onKeyPress={onKeyPress}
                    form={form}
                />
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                    label={t('forgotPassword.buttonLabel')}
                    className={classes.button}
                />
                {succesMessage && (
                    <Box mt={2}>
                        <Typography variant="body1">{t('forgotPassword.succesMessage')}</Typography>
                    </Box>
                )}
            </Grid>
            <LoaderModal loading={mutationLoading} />
        </Grid>
    );
};

export default ForgotPassword;
