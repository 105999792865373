import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Block, ReadMore } from 'shared';
import VacancyItem from './VacancyItem';
import { useQuery } from '@apollo/client';
import { GET_VACANCIES } from './queries';
import { convertToSlug } from 'helpers';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    list: {
        marginBottom: 30
    },
    readMore: {
        alignSelf: 'flex-end'
    },
    block: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4)
        }
    }
}));

const VacancyList = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { data = {} } = useQuery(GET_VACANCIES, {
        variables: {
            pagination: { page: 0, pageSize: 5 },
            filter: { draft: false }
        }
    });
    const { vacancies = {} } = data;

    return (
        <Block className={classes.block} title="Bekijk de nieuwste kansen">
            <Grid className={classes.list}>
                {data && Object.keys(data).length < 1 && (
                    <VacancyItem key={0} title={'Geen kansen gevonden'} place="" corporation="" link={'/'} />
                )}
                {vacancies &&
                    vacancies.vacancies &&
                    vacancies.vacancies.map((item, idx) => {
                        return (
                            <VacancyItem
                                key={idx}
                                title={item.title}
                                place={item.city ? item.city.name || '' : ''}
                                corporation={item.corporation.name}
                                link={`/kansen/${item.id}/${convertToSlug(item.title)}`}
                                id={item.id}
                                originURL={item.originURL}
                                crawled={item.crawled}
                            />
                        );
                    })}
            </Grid>
            {data && Object.keys(data).length > 0 && (
                <ReadMore path="/kansen" className={classes.readMore} label={t('vacancyList.buttonText')} />
            )}
        </Block>
    );
};

export default VacancyList;
